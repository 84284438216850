.NSF-ActionButton {
  border: none;
  background: $color-dark-red;
  color: $color-white;
  border-radius: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;

  &:disabled {
    background: $color-unselected-text;
    cursor: not-allowed;
  }

  &--white {
    background: $color-white;
    color: $color-unselected-text;
  }
  &--black {
    background: $color-dark-black;
    color: $color-white;
  }

  > p {
    display: inline-block;
    margin: 0;
    padding: 0;
    border: none;
    padding: 0.3rem 2rem;
    // line-height: $base-font-size;
    // font-size: $base-font-size;
  }
}
