.NSF-SubProject {

  & h2 {
    font-size: 1.5rem;
  }

  > :first-child {
    padding: 1rem 0 0 2rem;
    font-weight: 400;
    color: $color-general-text;
  }

  > * {
    margin-bottom: 1rem;
  }
}