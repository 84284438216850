.NSF-Employees-Filter {
  display: flex;
  width: 30%;
  margin: 0 2rem 0 auto;
  
  &--SearchInput {
    & span {
      padding: 0;
      margin-bottom: 0.5rem;
    }
    
    & input {
      border-radius: 25px;
      background: $color-white;
      padding: .5rem 20px;
    }

  }
}