.NSF-AssetsList {
  margin-top: 1rem;

  &__pagination {
    margin-top: 1rem;

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      
      & .previous, .next {
        padding: 0 2rem;
      }
      & li {
        padding: 0 0.5rem;
        cursor: default;
      }
      & .disabled {
        color: $color-general-text;
      }
      & .active {
        color: $color-dark-red;
        font-size: 1.15em;
      }
    }
  }
}

.NSF-AssetsFilter {
  display: flex;

  &--item {
    width: 33%;
    margin-left: 0.5rem;

    & .Dropdown-root {
      margin-top: 0.5rem;
      
      & .Dropdown-control {
        border: none;
        border-radius: 25px;
        padding: 8px;
        font-size: 0.85em;
      }
    }

    & .Dropdown-root.is-open {
      & .Dropdown-control {
        border-radius: 12px 12px 0 0;
      }
    }
    & .Dropdown-placeholder {
      text-align: center;
    }
  }
  &--item:first-of-type {
    width: 50%;
  }

  &--SearchInput {
    & span {
      padding: 0;
      margin-bottom: 0.5rem;
    }
    & input {
      border-radius: 25px;
      background: $color-white;
      padding: .5rem 20px;
    }
  }
  &--label {
    font-weight: 300;
    color: $color-general-text;
    margin-bottom: 0.5rem;
  }

  & .NSF-Input {
    margin: 0;
  }
}

.NSF-AssetForm {

  & .NSF-EmployeeSelect {
    padding: 1rem 1rem 0 1rem;
  }
  &__form {
      width: 100%;
      background: $color-white;
      margin-top: 1rem;
      display: flex;
      flex-flow: row wrap;
      padding: 1rem;

      &--input {
          width: 50%;
          padding: 0.5rem;

          & .NSF-Input > input {
              background: $color-gray;
          }
      }

      &--new {
          padding: 1rem;
          margin: auto 0;
      }
  }

  &__submit--button {
      width: 100%;
      padding: 1rem;
      background: $color-gray;
  }

  &__error {
    margin-top: 3px;
    width: 100%;
    background-color: $color-white;
    padding: 1rem;
    color: $color-light-red;
  }
}
