.NSF-Asset {

    padding: 1rem 2rem;


    &--type {
        color: $color-light-red;
        text-transform: uppercase;
    }
    
    &--title {
        margin-top: 0.5rem;
        color: $color-unselected-text;
        text-transform: uppercase;
    }
    
    &--img {
        margin-top: 0.5rem;
        width: 100%;
    }

    &--description {
        color: $color-unselected-text;
        margin-top: 0.5rem;
    }
    
    &--specs {
        color: $color-unselected-text;
        font-size: 0.8rem;
        margin-top: 0.8rem;

        &-detailed {
            color: $color-unselected-text;
            font-size: 0.8rem;
            margin-top: 0.4rem;
        }
    }
    &--bottom {
        margin-top: 1rem;
        height: 30px;
    }
    &--price {
        display: inline-block;
        font-size: 1.2rem;
        line-height: 30px;
    }

    
    & button {
        float: right;
        height: inherit;

        & > p{
            font-size: 0.8rem;
            padding: 0.3rem 0.5rem;
        }
    } 

}

.NSF-AssetHistory {

    .NSF-Row {
        margin: 1rem 0;
    }

    &__Headers {
         display: flex;
         flex-flow: row wrap;
         padding: 0.5rem 3rem;
         background: $color-white;
        & h2 {
            width: 25%;
            font-size: 1.2rem;
            font-weight: 400;
            color: $color-general-text;
        }   
    }

    &__Row {
        display: flex;
        flex-flow: row wrap;
        padding: 0.5rem 3rem;
        background: $color-white;
        & p {
            width: 25%;
            padding: 0.5rem;
        }
    }
    &__Row--NoData {
        display: flex;
        flex-flow: row wrap;
        padding: 2rem 3rem;
        background: $color-white;
        & h2 {
            margin: auto;
        }
    }
    &__Row--button {
        display: flex;
        flex-flow: row wrap;
        background-color: $color-white;
        padding: 1rem;

        & .NSF-ActionButton {
            margin-left: auto;
        }
    }
}

.NSF-Devices {
    & .NSF-ActionButton {
        float: right;
        margin: 0 1rem 0.5rem 0;
    }
    & .NSF-PageTitle {
        width: 20%;
        margin: 0;
        padding: 1rem 0 0 1rem;
    }
    & .NSF-AssetsFilter {
        width: 80%;
    }
}