.NSF-Employees--New {
  margin-top: 1rem;
}
.NSF-EmployeeForm {
  background: $color-white;

  &__form {
    padding: 1rem;
    width: 100%;
  }

  &--back {
    padding: 0 0 1rem 1rem;
    margin: 0 1rem 0 auto;
  }
}
.Remove-Employee {
  justify-content: flex-end;
  padding: 1rem 1rem 0 0;
}