.NSF-Overview {
  width: 100%;
  display: flex;
  flex-direction: column;

  > .NSF-Row:first-child {
    justify-content: space-between;
  }

  > .NSF-Row {
    > * {
      margin-right: 1rem;
      margin-top: 1rem;
    }
    > h2 {
      margin: 1rem 0rem 0rem 2rem;
      font-weight: 400;
      font-size: 1.5rem;
      color: $color-general-text;
    }
  }
}
