.NSF-EmployeesList {
  width: 100%;
  margin-top: 1rem;

  &__row {
    width: 100%;
    display: flex;
    padding: 0.5rem;
    background: $color-white;
    color: $color-general-text;
    margin-top: 2px;

    & button {
      cursor: pointer;
      border: none;

      background: $color-white;

      &:focus {
        outline: none;
      }
    }

    &--arrow {
      margin: 0.8rem 0;
      width: 5%;
      &-animation {
        transition: transform 0.2s ease-in-out;
      }
      &-active {
        transform: rotate(90deg);
      }
    }

    &--name {
      width: 30%;
      padding: 0.8rem 0;

      p {
        cursor: default;
      }
    }

    &--devices {
      width: 40%;
    }
    &--devices--wide {
      width: 50%;
    }

    &--order {
      width: 10%;
      color: $color-dark-red;
      padding: 0.8rem 0;
      & a {
        text-decoration: none;
        color: inherit;
      }
    }

    &--modify {
      width: 11%;
      color: $color-dark-red;
      padding: 0.8rem 0;
      & a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}

.NSF-EmployeeDevices {
  display: flex;
  flex-flow: row wrap;
  padding-right: 2rem;

  &__deviceIcon {
    position: relative;

    margin: 0.3rem;

    width: 32px;
    height: 32px;

    padding: 0.5rem;
    border-radius: 50%;

    &--ORDERED {
      background-color: $color-status-ordered;
    }
    &--SHIPMENT {
      background-color: $color-status-shipment;
    }
    &--DELIVERED {
      background-color: $color-status-delivered;
    }
    &--undefined {
      background-color: $color-gray;
    }
  
    .NSF-DeviceStatus--tooltip {
      min-width: 300px;
      visibility: hidden;
      background-color: $color-gray;
      border-radius: 6px;
      padding: 10px;
      box-shadow: 3px 3px 15px rgba(0,0,0,0.2);
      font-weight: 300;

      position: absolute;
      z-index: 1;

      top: 120%;
      left: 50%;
      margin-left: -16px;

      &-displayName {
        display: block;
        padding-bottom: 0.5rem;
        font-weight: 400;
      }
    }

    &:hover {
      .NSF-DeviceStatus--tooltip {
        visibility: visible;
      }
    }
  }
}

.NSF-DeviceList {
  display: none;
  transition: all ease-in-out 0.3s;
  z-index: -100;
  visibility: hidden;
  width: 100%;

  &--show {
    display: block;
    visibility: visible;
    z-index: 1;
    opacity: 1;
    transform: translateY(1);
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 4rem;
    margin-bottom: 2px;
    background: $color-white;
    color: $color-general-text;

    &--icon {
      width: 10%;
      display: flex;
      justify-content: center;
    }

    &--name {
      width: 30%;
      padding: 0.8rem 0;
    }

   &--status {
      width: 25%;
      font-weight: 300;
      padding: 0.8rem 0;
    }

    &--orderinfo {
      width: 30%;
      font-weight: 300;
      padding: 0.8rem 0;
    }

    &--details {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      background: $color-white;
      padding: 0.5rem 1rem 0 7.5rem;
    }
    
    &--info {
      width: 50%;
      padding: 1rem 1rem 0 0;
    }
    &--title {
      width: 100%;
      margin: auto;
      color: $color-general-text;
      padding-bottom: 3px;
    }
    &--value {
      width: 100%;
      margin: auto;
      font-weight: 300;
      
    }

    &--asset-modify {
      color: $color-dark-red;
      margin-left: auto;
      padding: 0.2rem 1rem 0 1rem;
      & a {
        display: block;
        text-decoration: none;
        color: inherit;
        padding-left: 1rem;
      }
    }

    & button {
      cursor: pointer;
      border: none;
  
      background: inherit;
  
      &:focus {
        outline: none;
      }
    }
  
    &--arrow {
      width: 5%;
      transition: transform 0.2s ease-in-out;
      &-active {
        transform: rotate(90deg);
      }
    }

  }
}
