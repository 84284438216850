
.NSF-OrderEmployeesList__row {

  &--arrow {
    margin: 0.8rem 0;
    width: 5%;
  }
  
  &--name {
    width: 25%;
    padding: 0.8rem 0;
  }
  &--assets {
    width: 50%;
    padding: 0.8rem 0;
  }
  
  &--order {
    width: 20%;
    color: #8e1b19;
    padding: 0.8rem 0;
    font-size: 0.8em;
  }
}

.NSF-OrderDeviceList__row {
  background-color: $color-white;
  margin-bottom: .5rem;
 
  &--asset {
    padding: 1rem 1rem 1rem 5rem;  
    
    &--name {
      width: 30%;
    }
    
    &--price { 
      font-weight: 300;
      width: 30%;
    }
  }
 
  &--noDevices {
    padding: 1rem 1rem 1rem 5rem;
    font-weight: 300;
    background-color: $color-white;
  }
}