.NSF-TimeSheet {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: $color-white;

  &__name {
    width: 20%;
  }

  &__date {
    width: 20%;
  }

  &__description {
    width: 50%;
  }

  &__hours {
    width: 10%;
  }
}