.NSF-OrderForm {

    font-family: "Josefin Sans", sans-serif !important;

    &__employees {

        &--header {
            display: flex;
            width: 100%;

            &-title {
                width: 50%;
            }
            &-list {
                width: 25%;
            }
            &-search {
                width: 25%;
            }
            &-new {
                margin-left: auto;
            }
        }
    }
    &__steps {
        width: auto;
        margin: 1rem;
        padding: 1rem;
        $stepHeight: 40px;
        
        &--incomplete {
            width: $stepHeight;
            height: $stepHeight;
            text-align: center;
            vertical-align: middle;
            line-height: $stepHeight;
            font-size: 1.1em;    
            background-color: $color-gray;
            border: solid 2px $color-dark-red;
            border-radius: 50%;
        }
        &--complete {
            font-size: $stepHeight;
            color: green;
        }
    }

    &__navigation {
        padding: 1rem;
        background-color: $color-white;
        font-size: 1.3em;
        width: auto;
        color: $color-unselected-text;

        & h2 {
            margin-top: .4rem;
            font-weight: 300;
            & > span {
                color: $color-dark-black;
                font-weight: 400;
            }
        } 
            
        & button > p {
            font-size: 1rem;
            padding: 0.4rem 1rem;
        }
    }

    &__assets {
        font-size: 1.1em;
        padding: 1rem 0;

        &--container {
            justify-content: flex-start;
        }

        &--item {
            display: flex;
            flex-flow: row wrap;
            text-align: center;
            padding: 0.5rem;
            margin: 0.5rem;
            background-color: $color-white;
            width: 30%;

            &--description {
                width: 100%;
                padding-bottom: 1rem;
                font-weight: 300;

                & h2 {
                    font-size: 1.1em;
                }
                & p {
                 font-size: .8em;   
                }
            }
            &--price {
                width: 50%;
                margin-top: .3rem;
                font-size: 1em;
            }
            & button {
                width: 50%;
                padding: 3px 0;
            }
        }
    }
    &__confirm {
        padding: 1rem;
        width: 100%;
        margin: auto;
        background: $color-white;
        &--item {
            background: $color-gray;
            margin: .5rem 0;
            padding: 1rem;
        }
        &--total {
            padding: 0.5rem;
            & p {
                float: right;
            }
        }
    }
    &__total {
        margin-top: 2px;
        display: flex;
        width: 100%;
        padding: 1rem;
        background-color: $color-white;
        padding-left: 2rem;

        &--price {
            margin-left: auto;
            padding: 0.3rem;
            font-weight: 300;
            width: 40%;

            & span {
                padding-left: 10px;
                font-weight: 400;
            }
        }
        & button {
            padding: 2px 0;
            & p {
                padding: 0.3rem 1.5rem;
            }
        }
    }
}

.NSF-OrderValidate {
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    &__filter {
        background-color: $color-unselected-text;
        color: $color-white;
        text-transform: uppercase;
        
    }
    &__item {
        padding: .5rem;
        width: 100%;
        display: flex;
        flex-flow: row;

        & p {
            width: 33%;
        }
    }

    &__confirm {
        background-color: $color-white;
        margin-top: 2px;
        padding: 1rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
}